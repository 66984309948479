/* Colors */
$color-foreground: #ffffff;
$color-background: #1b1e24;
$color-elevation-100: #24272d;
$color-elevation-200: #2f343e;
$color-elevation-300: #3d4554;
$color-primary: #d6c8ff;

/* Typography */
$type-font-heading: "Red Hat Display", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
$type-font-primary: "Red Hat Text", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;

$type-regular: 400;
$type-medium: 500;
$type-bold: 700;
$type-black: 900;

/* Layout */
$page-horizontal-padding: 40px;
$page-horizontal-padding-mobile: 24px;
$page-width-limit: 1320px;

body {
  margin: 0;
  background-color: $color-background;
  color: $color-foreground;
  font-family: $type-font-primary;
  font-size: 15px;
  line-height: 144%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: $color-foreground;
    text-decoration: none;
    outline-color: $color-primary;

    &.cta {
      display: inline-block;
      padding: 8px 16px;
      border-radius: 4px;
      font-weight: $type-bold;
      font-size: 14px;
      letter-spacing: 0.4px;
      font-family: $type-font-primary;
      border: 0;
      cursor: pointer;
      transition: all 0.2s ease;

      &.primary {
        background-color: $color-primary;
        color: $color-background;
      }

      &.desktop {
        display: inline-block;
      }

      &.mobile {
        display: none;
      }

      &:hover {
        opacity: 0.6;
      }

      &:active {
        transform: scale(0.96);
      }
    }
  }

  h1,
  h2 {
    margin: 0;
    font-family: $type-font-heading;
    font-size: 48px;
    line-height: 112%;
    letter-spacing: -0.96px;
    font-weight: $type-black;
  }

  h3 {
    margin: 0;
    font-family: $type-font-heading;
    font-size: 24px;
    line-height: 112%;
    letter-spacing: -0.56px;
    font-weight: $type-black;
  }

  a,
  input,
  button {
    outline-color: $color-primary;
  }

  img {
    width: 100%;
  }

  .highlight {
    color: $color-primary;
  }

  @media screen and (max-width: 1200px) {
    font-size: 13px;
    h1,
    h2 {
      font-size: 32px;
      line-height: 112%;
    }

    h3 {
      font-size: 22px;
      line-height: 112%;
    }
  }
}

::selection {
  color: $color-background;
  background: $color-primary;
}
