@import "common/styles/_base.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 2px solid transparent;
  background-color: rgba(27, 30, 36, 1);
  backdrop-filter: blur(8px);
  transition: all 0.4s ease;

  &.sticky {
    background-color: rgba(27, 30, 36, 0.88);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid $color-elevation-100;

    .wrapper {
      padding: 12px $page-horizontal-padding;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    margin: 0 auto;
    padding: 32px $page-horizontal-padding;
    box-sizing: border-box;
    max-width: $page-width-limit;
    transform: translateZ(0);
    transition: all 0.2s ease;

    .logo {
      height: 20px;
      transition: opacity 0.4s ease;

      img {
        width: unset;
        height: 100%;
        max-height: 100%;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .navigation {
      .item {
        font-size: 14px;
        font-weight: $type-medium;
        letter-spacing: 0.5px;
        margin-right: 40px;
        transition: all 0.4s ease;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 0.6;
        }
      }

      .button {
        &.desktop {
          display: inline-block;
        }
        &.mobile {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &.sticky {
      .wrapper {
        padding: 16px $page-horizontal-padding-mobile;

        .navigation {
          .item {
            color: $color-foreground;
          }
        }
      }
    }

    .wrapper {
      grid-template-columns: auto auto;
      padding: 16px $page-horizontal-padding-mobile;
      max-width: unset;

      .logo {
        height: 18px;
        transform: translateY(-2px);
      }

      .navigation {
        display: none;
        .item {
          font-size: 14px;
          font-weight: $type-medium;
          line-height: 140%;
          letter-spacing: 1px;
          margin-right: 16px;
          color: $color-foreground;
          vertical-align: middle;
        }

        .cta {
          &.desktop {
            display: none;
          }
          &.mobile {
            vertical-align: middle;
            display: inline-block;
            font-size: 13px;
            letter-spacing: 0;
            padding: 8px 12px;

            .label {
              & > div {
                svg {
                  width: 16px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
