@import "common/styles/_base.scss";

.footer {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 112px $page-horizontal-padding;
    box-sizing: border-box;
    max-width: $page-width-limit;

    .attributions {
      font-size: 13px;
      letter-spacing: 0.4px;

      span,
      a {
        opacity: 0.7;
        transition: all 0.2s ease;
      }

      &:hover {
        a {
          opacity: 1;
          color: $color-primary;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .wrapper {
      padding: 64px $page-horizontal-padding-mobile;
      text-align: left;

      .attributions {
        font-size: 12px;
      }
    }
  }
}
