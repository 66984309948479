@import "common/styles/_base.scss";

.upvote-and-quick-feedback-wrapper {
  &.header-component {
    justify-self: end;

    .upvote-counter {
      .upvote-button {
        padding: 0;
        .counter {
          margin-right: 12px;
          padding: 4px 6px 6px;
          min-width: 24px;
          min-height: 33px;
          box-sizing: border-box;

          svg {
            width: 12px;
            height: 12px;
            margin-bottom: 0;
          }

          .value {
            font-size: 13px;
            line-height: 100%;
            font-weight: $type-bold;
          }

          .loader {
            div {
              transform: translateY(1.7px);
              filter: contrast(0);
            }
          }
        }

        .label {
          .title {
            font-size: 14px;
            font-weight: $type-medium;
            margin-bottom: 0;
          }

          .description {
            display: none;
          }
        }
      }
    }

    .quick-feedback-modal-portal {
      .quick-feedback-modal-overlay {
        .ReactModal__Content--after-open {
          position: fixed !important;
          top: unset !important;
          bottom: -156px !important;
          left: unset !important;
          right: 0 !important;
          transform: translate(-16px, 0);
          border: none !important;
          padding: 0 !important;
          min-width: 480px;
          background: transparent !important;
          animation: modalFadeIn 0.3s ease;
        }
      }
    }
  }

  .upvote-counter {
    .upvote-button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0 16px 0 0;
      text-align: left;
      color: $color-foreground;
      font-family: $type-font-primary;
      cursor: pointer;
      transition: all 0.3s ease;

      &:not(.active) {
        &:hover {
          opacity: 0.7;
        }

        .counter {
          animation: bounceOut 0.3s ease 1;
        }
      }

      // TODO: Find a better implementation for A11Y
      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.active {
        animation: shine 1s ease;
        &:hover {
          mask-image: linear-gradient(
            75deg,
            rgba(214, 200, 255, 0.7) 30%,
            #d6c8ff 50%,
            rgba(214, 200, 255, 0.7) 70%
          );
          mask-size: 200%;
        }

        .counter {
          position: relative;
          background-color: $color-primary;
          color: $color-background;
          animation: bounce 0.3s ease 1;

          svg path {
            fill: $color-background;
          }
        }
      }

      .counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        padding: 12px 8px;
        min-width: 40px;
        border-radius: 4px;
        background-color: $color-elevation-200;
        color: $color-foreground;
        transition: all 0.3s ease;

        svg {
          margin-top: -2px;
          margin-bottom: 2px;

          path {
            fill: $color-foreground;
          }
        }

        .value {
          font-size: 20px;
          line-height: 100%;
          font-weight: $type-bold;
        }
      }

      .label {
        .title {
          font-size: 20px;
          // line-height: 160%;
          font-weight: $type-bold;
          margin-bottom: 6px;
        }

        .description {
          font-size: 14px;
          line-height: 120%;
          letter-spacing: 0.2px;
          opacity: 0.6;
        }
      }
    }
  }

  .quick-feedback-modal-portal {
    position: relative;

    .quick-feedback-modal-overlay {
      .ReactModal__Content--after-open {
        top: -124px !important;
        bottom: unset !important;
        left: 0 !important;
        right: 0 !important;
        transform: translate(450px, 0);
        border: none !important;
        padding: 0 !important;
        background: transparent !important;
        animation: modalFadeIn 0.3s ease;

        .early-access-popup {
          margin: 8px;

          .title {
            font-size: 16px !important;
          }

          input {
            font-size: 14px !important;
          }
        }

        .quick-feedback,
        .early-access-popup {
          position: relative;
          display: flex;
          flex-direction: column;
          border: 1px solid $color-elevation-200;
          border-radius: 4px;
          padding: 24px;
          background: rgba(27, 30, 36, 1);

          @supports (backdrop-filter: blur(8px)) or
            (-webkit-backdrop-filter: blur(8px)) {
            background: rgba(27, 30, 36, 0.9);
            backdrop-filter: blur(8px);
          }

          .close-button {
            position: absolute;
            top: 24px;
            right: 24px;
            padding: 8px 12px;
            margin-top: -8px;
            margin-right: -12px;
            cursor: pointer;
            transition: opacity 0.2s ease;

            &:hover {
              opacity: 0.6;
            }
          }

          .title {
            font-size: 20px;
            line-height: 100%;
            font-weight: $type-bold;
            margin-bottom: 24px;
            margin-right: 24px;
          }

          .input-value {
            border: 1px solid $color-elevation-200;
            border-radius: 4px;
            box-shadow: none;
            background-color: transparent;
            color: $color-foreground;
            font-family: $type-font-primary;
            font-size: 16px;
            line-height: 160%;
            padding: 8px 12px;
            margin-bottom: 16px;
            resize: none;

            &:focus {
              box-shadow: none;
              border: 1px solid $color-primary;
              outline: none;
            }
          }

          .submit-button {
            padding-top: 8px;
            padding-bottom: 8px;
            border: none;
            box-shadow: none;
            background-color: $color-elevation-200;
            color: $color-primary;
            font-family: $type-font-primary;
            font-size: 14px;
            font-weight: $type-bold;
            line-height: 160%;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            transition: opacity 0.2s ease;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &.header-component {
      .upvote-counter {
        .upvote-button {
          padding: 0;
          .counter {
            margin-right: 8px;
            padding: 3px 4px 5px;
            min-width: 36px;

            svg {
              width: 10px;
              height: 10px;
            }

            .value {
              font-size: 13px;
            }
          }

          .label {
            .title {
              font-size: 13px;
            }
            .description {
              line-height: 144%;
              font-size: 12px;
            }
          }
        }
      }

      .quick-feedback-modal-portal {
        .quick-feedback-modal-overlay {
          .ReactModal__Content--after-open {
            position: fixed !important;
            // top: calc(100vh - 160px) !important;
            bottom: -160px !important;
            left: 0 !important;
            right: 0 !important;
            min-width: unset;
            transform: translate(0, 0);
            animation: modalFadeInMobile 0.2s ease;
            // background: rgba(0, 0, 0, 0.2) !important;
            background: transparent !important;
            border-radius: 0 !important;
            border-top-left-radius: 16px !important;
            border-top-right-radius: 16px !important;
            z-index: 100;

            @supports (backdrop-filter: blur(8px)) or
              (-webkit-backdrop-filter: blur(8px)) {
              backdrop-filter: blur(8px);
            }

            .quick-feedback,
            .early-access-popup {
              padding: 16px 14px;
              margin: 10px 8px;
              border-radius: 16px;

              @supports (backdrop-filter: blur(8px)) or
                (-webkit-backdrop-filter: blur(8px)) {
                background: rgba(27, 30, 36, 0.96);
                backdrop-filter: blur(8px);
              }

              .close-button {
                margin-top: -16px;
                margin-right: -20px;
              }

              .title {
                font-size: 18px;
                margin-right: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes modalFadeIn {
  0% {
    transform: translate(-16px, -24px);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(-16px, 0px);
  }
}

@keyframes modalFadeInMobile {
  0% {
    transform: translate(0, 112px);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0px);
  }
}
