@import "common/styles/_base.scss";

.home {
  .page-section {
    margin: 0 auto 160px;
    width: 100%;
    max-width: $page-width-limit;
    padding: 112px $page-horizontal-padding;
    box-sizing: border-box;
  }

  .hero {
    padding-top: 240px;

    .hero-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 160px;

        .headline {
          // font-size: 48px;
          margin: 0 0 64px 0;
        }
      }

      .snapshot {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        [class^="snap"] {
          grid-column: 1 / 1;
          grid-row: 1 / 1;
        }

        .snap-background {
          // transform: scale(1.1);
          // opacity: 0.4;
          transition: all 0.6s ease;
        }

        .snap-main-placeholder,
        .snap-main {
          transform: translateY(-8px);
          width: 320px;
          border-radius: 40px;
        }

        .snap-frame {
          transform: translateY(-8px);
          width: 357px;
        }
      }
    }

    &.active {
      .hero-wrapper {
        .snapshot {
          .snap-background {
            opacity: 0.2;
            transform: scale(0.9) translateY(16px);
          }
        }
      }
    }
  }

  .bullet-features {
    margin: 0 auto;
    padding-bottom: 0;

    .bullet-features-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;
      width: 100%;

      .bullet-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        // background-color: $color-elevation-100;
        border: 1px solid $color-elevation-100;
        border-radius: 12px;
        text-align: center;

        // &:hover {
        //   border: 2px dashed $color-elevation-100;
        //   .snapshot {
        //     transform: scale(1.04);
        //   }
        // }

        .snapshot {
          position: relative;
          margin-bottom: 48px;
          transition: transform 0.2s ease;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 120px;
            border-radius: 8px;
            background: $color-background;
          }

          [class^="bullet-snap-"] {
            width: 380px;
          }
        }

        &:nth-of-type(1) {
          .snapshot {
            &:after {
              bottom: 0;
              background: linear-gradient(
                180deg,
                rgba($color-background, 0) 0%,
                rgba($color-background, 1) 64%,
                rgba($color-background, 1) 100%
              );
            }
          }
        }

        &:nth-of-type(2) {
          .snapshot {
            &:after {
              top: 0;
              background: linear-gradient(
                0deg,
                rgba($color-background, 0) 0%,
                rgba($color-background, 1) 98%,
                rgba($color-background, 1) 100%
              );
            }
          }
        }

        .description {
          .title {
            margin-bottom: 16px;
          }
          .label {
            max-width: 460px;
            opacity: 0.5;
            line-height: 144%;
          }
        }
      }
    }
  }

  .catchy-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0 auto;
    text-align: center;

    .headline {
      text-align: center;
      margin-bottom: 0px;
      font-size: 56px;
    }
  }

  .features {
    padding-top: 0;

    .features-wrapper {
      .feature-item {
        display: grid;
        grid-template-areas: "snapshot description";
        column-gap: 40px;
        align-items: center;
        width: 100%;
        margin-bottom: 160px;
        text-align: left;

        &:nth-child(1) {
          grid-template-areas: "description snapshot";

          .description {
            justify-self: right;
          }
        }

        &:nth-child(2) {
          .snapshot {
            justify-self: right;
          }

          .description {
            justify-self: center;
          }
        }

        .snapshot {
          grid-area: snapshot;
          display: grid;
          justify-items: center;
          align-items: center;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;

          [class^="snap"] {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
          }

          .snap-preview {
            transform: translateY(-8px);
            width: 320px;
          }

          .snap-frame {
            transform: translateY(-8px);
            width: 357px;
          }
        }

        .description {
          grid-area: description;

          h2 {
            margin-bottom: 32px;
          }

          p {
            font-size: 17px;
            line-height: 144%;
            max-width: 480px;
          }
        }
      }
    }
  }

  .just-put-it-on-your-tabb {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 400px;
    text-align: center;

    .tagline-wrapper {
      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;
      }

      transform: translateX(-40px);

      .tagline-image {
        margin: 0 auto;
        width: 475px;
      }
    }
  }

  .contact {
    width: 100%;
    margin-bottom: 0;
    max-width: unset;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: $color-primary;
    color: $color-background;

    .contact-wrapper {
      margin: 0 auto;
      padding: 40px $page-horizontal-padding;
      width: 100%;
      max-width: $page-width-limit;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .description {
        h2 {
          margin-bottom: 48px;
        }

        .blurb {
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 0.4px;
          max-width: 544px;
          margin-bottom: 24px;
        }

        .email {
          margin-bottom: 24px;

          span {
            color: $color-background;
            font-weight: $type-medium;
          }

          a {
            margin-left: 4px;
            font-size: 17px;
            line-height: 160%;
            font-weight: $type-black;
            color: $color-background;
            transition: opacity 0.2s ease;

            &:hover {
              opacity: 0.6;
            }
          }
        }

        .feedback-form {
          display: flex;
          flex-direction: column;

          //   @supports (backdrop-filter: blur(8px)) or
          //     (-webkit-backdrop-filter: blur(8px)) {
          //     background: rgba(27, 30, 36, 0.9);
          //     backdrop-filter: blur(8px);
          //   }

          .close-button {
            position: absolute;
            top: 24px;
            right: 24px;
            padding: 8px 12px;
            margin-top: -8px;
            margin-right: -12px;
            cursor: pointer;
            transition: opacity 0.2s ease;

            &:hover {
              opacity: 0.6;
            }
          }

          .title {
            font-size: 20px;
            line-height: 100%;
            font-weight: $type-bold;
            margin-bottom: 24px;
            margin-right: 24px;
          }

          .input-value {
            border: 1px solid $color-elevation-200;
            border-radius: 4px;
            box-shadow: none;
            background-color: transparent;
            color: $color-background;
            font-family: $type-font-primary;
            font-size: 16px;
            line-height: 160%;
            padding: 8px 12px;
            margin-bottom: 16px;
            resize: none;

            &:focus {
              box-shadow: none;
              //   border: 1px solid $color-foreground;
              outline-color: $color-foreground;
            }
          }

          .submit-button {
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 4px;
            border: none;
            box-shadow: none;
            background-color: $color-elevation-200;
            color: $color-primary;
            font-family: $type-font-primary;
            font-size: 14px;
            font-weight: $type-bold;
            line-height: 160%;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            transition: opacity 0.2s ease;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .contact-illustration {
        width: 512px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .page-section {
      margin: 0 auto 80px;
      max-width: 100%;
      padding: 64px $page-horizontal-padding-mobile;
    }

    .hero {
      flex-direction: column;
      padding-top: 144px;

      .hero-wrapper {
        .introduction {
          margin-bottom: 40px;

          .headline {
            font-size: 36px;
            margin-bottom: 40px;
          }
        }

        .snapshot {
          .snap-background {
            display: none;
          }

          .snap-main-placeholder,
          .snap-main {
            width: 70vw;
            max-width: 320px;
          }

          .snap-frame {
            width: 78vw;
            max-width: 360px;
          }
        }
      }
    }

    .bullet-features {
      .bullet-features-wrapper {
        grid-template-columns: 1fr;
        row-gap: 24px;

        .bullet-item {
          padding: 16px;
          border-radius: 8px;

          .snapshot {
            margin-bottom: 24px;

            [class^="bullet-snap-"] {
              width: 100%;
              max-width: 380px;
            }
          }
        }
      }
    }

    .catchy-text {
      height: 40vh;

      .headline {
        font-size: 32px;
      }
    }

    .features {
      margin-bottom: 0;
      .features-wrapper {
        .feature-item {
          grid-template-columns: 1fr;
          grid-template-areas:
            "snapshot"
            "description";
          text-align: center;

          &:nth-child(1) {
            grid-template-areas:
              "snapshot"
              "description";

            .description {
              justify-self: unset;
            }
          }

          &:nth-child(2) {
            .snapshot {
              justify-self: unset;
            }
          }

          .description {
            p {
              font-size: 15px;
            }
          }

          .snapshot {
            .snap-preview {
              width: 70vw;
              max-width: 320px;
            }
            .snap-frame {
              width: 78vw;
              max-width: 360px;
            }
          }
        }
      }
    }

    .just-put-it-on-your-tabb {
      margin-bottom: 200px;

      .tagline-wrapper {
        transform: translateX(-1%);

        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }

        .tagline-image {
          width: 80%;
        }
      }
    }

    .contact {
      .contact-wrapper {
        padding: 0;
        display: grid;

        .description {
          grid-row: 2;
          h2 {
            margin-bottom: 24px;
          }

          .blurb {
            font-size: 14px;
            margin-bottom: 24px;
          }

          .email {
            margin-bottom: 24px;
            a {
              font-size: 17px;
            }
          }
        }

        .contact-illustration {
          grid-row: 1;
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }
}
