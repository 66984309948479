@import "common/styles/_base.scss";

.form-access {
  padding-top: 40px;
  text-align: center;

  .page-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: $page-width-limit;
    padding: 112px $page-horizontal-padding;
    box-sizing: border-box;

    .illustration {
      margin: 0 auto 32px auto;
      max-width: 400px;

      img {
        max-width: 100%;
      }
    }

    h1 {
      margin-bottom: 24px;
    }
    p {
      margin: 0 auto 40px auto;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.8px;
      max-width: 720px;
    }
  }

  @media screen and (max-width: 1200px) {
    .page-wrapper {
      padding: 80px $page-horizontal-padding-mobile;

      .illustration {
        max-width: 240px;
      }

      h1 {
        margin-bottom: 16px;
      }

      p {
        font-size: 16px;
        margin-bottom: 32px;
      }
    }
  }
}
