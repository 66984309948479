@import "common/styles/_base.scss";

.early-access-form {
  width: 100%;
  max-width: 400px;

  .form-inputs {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    max-width: 400px;
    background-color: $color-elevation-100;
    border-radius: 4px;

    label {
      grid-row: 1;
      grid-column: 1;
      visibility: collapse;
    }

    input {
      grid-row: 1;
      grid-column: 1;
      width: 100%;
      box-sizing: border-box;
      background-color: transparent;
      border: 0;
      padding: 16px 20px;
      font-size: 16px;
      // letter-spacing: 0.2px;
      border-radius: 4px;
      font-weight: $type-medium;
      color: $color-foreground;
      font-family: $type-font-primary;

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .button {
      width: max-content;
      justify-self: end;
      background-color: transparent;

      .label > div {
        width: 28px !important;
        height: 28px !important;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .form-inputs {
      input {
        font-size: 14px;
        padding: 16px;
      }
    }
  }
}
