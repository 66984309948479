@import "common/styles/_base.scss";

.button {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: $type-bold;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: $type-font-primary;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease;

  &.primary {
    background-color: $color-primary;
    color: $color-background;
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    transform: scale(0.96);
  }

  @media screen and (max-width: 1200px) {
    padding: 6px 12px;
    font-size: 11px;
    letter-spacing: 0;
  }
}
